'use strict';

angular.module('enervexSalesappApp').controller('SignupCtrl', function($scope, Auth, Flash,$stateParams, _, $state) {
	$scope.user = {};
	$scope.errors = {};

	console.log("redirect is"+$stateParams.redirect)
	if ($stateParams.email){
		$scope.user.email = $stateParams.email
	}
	$scope.gotoLogin = function(){
		$state.go("login", {
			redirect: $stateParams.redirect
		}, {
			reload: true,
			inherit: false
		});
	}
	$scope.register = function(form) {
		$scope.submitted = true;
		$scope.success = false;
		if (form.$valid) {
			Flash.clear()
			Auth.createUser({
				name: {
					first: $scope.user.firstName,
					last: $scope.user.lastName,
				},
				email: $scope.user.email,
				password: $scope.user.password
			}).then(function(data) {
				
				$scope.success = true;
				var message = '<strong>Success!</strong> You successfully created account, please check your email for the confirmation.';
				Flash.create('success', message, 0, {}, true);
			}).catch(function(err) {
				
				$scope.errors = {};
				var message = '<strong>Error!</strong> Could not sign up.'
				if (err.data && err.data.errmsg && err.data.errmsg.indexOf('uplicate key error index: enervex-salesapp.users.$email_1 dup key')!= -1){
					var field = "email"
					form[field].$setValidity('mongoose', false);
					$scope.errors[field] = "email already in use";
				}
				Flash.create('danger',  message, 0, {}, true);
				_.each(err.data.errors, function(error, field) {
					if (field == "hashedPassword"){
						field = "password"
					}
					form[field].$setValidity('mongoose', false);
					$scope.errors[field] = error.message;
				});
			});
		}
	};
});
